import React, { useState,useEffect, useContext } from "react"
import {useHistory} from 'react-router-dom'
import '../CircleBtn_img/CircleBtnimg.css'
import {PaymentContext} from '../../../Context/PaymentContext'



const CircleBtnsImg = (props) => {
    const history = useHistory()
    const darkMode = 1800
    const [darkModeStyle, setDarkModeStyle] = useState({
        buttonStyles: 'btn-confirm',

    })
    const {setIsLoading,isLoading} = useContext(PaymentContext)


useEffect(()=>{
    if(darkMode >= 1800 || darkMode <= 600){
        setDarkModeStyle({
            buttonStyles: 'btn-confirm-dark',

        })
    }
    if(darkMode < 1800 || darkMode > 600){
        setDarkModeStyle({
            buttonStyles: 'btn-confirm',

        })
    }
}, [])

    const [timer] = useState({
        title: props.title,
        img: props.img,
        extra: props.class
    })
    const {time} = useContext(PaymentContext)
    const currentTime = new Date();
    var newTime = parseInt(`${currentTime.getHours()}${currentTime.getMinutes()}`)
    const expiredTime = newTime + time


    const api_data = {
        'fullname': localStorage.getItem('fullname'),
        'license_plate': localStorage.getItem('license'),
        'set_time': localStorage.getItem('startTime'),
        'exp_time': localStorage.getItem('expTime'),
        'location_id': localStorage.getItem('lot'),
        'phone': localStorage.getItem('phone'),
        'token': localStorage.getItem('token'),
        'amount': localStorage.getItem('totalServer'),
        'expireTime': localStorage.getItem('expTimeInSecs'),
        'realTimeServer': localStorage.getItem('realTime'),
        'startTime': localStorage.getItem('startTime'),
        'count': localStorage.getItem('count'),
        'paid': localStorage.getItem('total'),
        'previousId': localStorage.getItem('guid')
    }


    const sendDataSuccess = ()=>{


        setIsLoading(true)
        localStorage.setItem('isLoading',true)

        try {

            if (localStorage.getItem("typePay") == "extend"){

                var startTime = localStorage.getItem("endDateTimeStamp")

            }else{
                var startTime = Date.now()+10000
            }

        }catch (e){
            var startTime = Date.now()+10000

        }

        fetch(`${process.env.REACT_APP_URL_ONESHOT}/v2/Park/Reserve`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "fullName": api_data.fullname,
                "phoneNumber": api_data.phone,
                "plateNo": api_data.license_plate,
                "startDateTimeStamp": startTime,
                "duration": api_data.realTimeServer,
                "lotId":api_data.location_id,
                "PreviousId":api_data.previousId,
                "metaData":JSON.stringify({"sec":api_data.expireTime,"start":api_data.startTime,"end":api_data.exp_time,"count":api_data.count,"lot":api_data.location_id}),
            }),
        }).then((res) => res.json())
            .catch((error) => {
                alert('Error1:')})
            .then((data) => {

                if (data.data){
                    setIsLoading(true)
                    window.location.replace(data.data.url);

                    // callBack(data.data.reqId)

                }else {
                    alert(data.error.message)
                    setIsLoading(false)

                }
                // alert(data.data.token)
                // if(data.status === 400){
                //     setNotifications(`Error >>> ${data.message}`)
                // }
                // else{
                //     setNotifications(null)
                //     setClientSecret(data.clientSecret)
                // }
            })


    }

    // console.log(`${currentHours}:${currentMins}`)
    const payment_action = () => {
        if(props.link){
            history.push(props.link)
        }
        // Check local storage to see if it's Empty or Null
    if(localStorage.getItem('timeStore') === null){
        // if true then store given time to localstorage
        localStorage.setItem('timeStore', `${time}`)
      // console.log(expiredTime)
      //   history.push('/guest/')
        sendDataSuccess()
    }
    // We're checking to see if time exist if it does then capture and instatiate time as new time then perform task to that time to then push back to localstorage as new time post
    else if(localStorage.getItem('timeStore') != null){
        localStorage.removeItem('timeStore')
        updatingTime(time)

        // history.push('/guest/')
        sendDataSuccess()
    }

    }

    const updatingTime = (time) => {
            localStorage.setItem('timeStore', time)
    }

    return (<>
        {!isLoading && <> <span onClick={payment_action} className={`${darkModeStyle.buttonStyles} content-align ${timer.extra}`} style={{border:"1px solid #3caddc",borderRadius:"60px",color:"red !important"}}>
            <span   className='confirm_title'>{timer.title}</span>
        </span>
        </>}
        {isLoading && <span>Please Wait ...</span>}

    </>)
}
export default CircleBtnsImg;
