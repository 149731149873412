import React, { useContext, useState, useEffect, useCallback } from "react";
import { PaymentContext } from '../../Context/PaymentContext'
import "../Timer/Timer.css";
import '../../Components/Buttons/CircleBtns_txt/CircleBtnstxt.css'
import Tick from './../../Assets/tick.mp3'
import { Howl, Howler } from 'howler'
import Time from './Time'

import {
	CircularInput,
	CircularTrack,
	CircularProgress,
	CircularThumb,
} from "react-circular-input";
import TimerLogic from "./TimerLogic";
import { useMemo } from "react";
import {useLocation} from "react-router-dom";
import DailyParking from "../Buttons/24+/DailyParking";




const Timer = (props) => {
	// const today = new Date();
	const timerLogic = new TimerLogic();
	const time_obj = new Time()
	const location = useLocation();
	const { totalbilling, dayTime, dayTimeBilling, nightTime, nightTimeBilling, setExpiredTime, setStartTime, darkMode ,setPriceServer,priceServer ,timeServer,setTimeServer} = useContext(PaymentContext)
	// const pGenerator = new PaymentGenerator();
	const [value, setValue] = useState(null);
	const [lot, setLot] = useState(null);
	const [validateDayTime, setDayTimeValidation] = useState()
	const [cameraInfo, setCameraInfo] = useState()
	const [allTotal, setAllTotal] = useState('0')
	const [reserveAmount, setReserveAmount] = useState('0')
	const [plateNo, setPlateNo] = useState()
	const [validateNightTime, setNightTimeValidation] = useState()
	const [darkModeStyle, setDarkModeStyle] = useState({
		circleContainer: 'timeInfo',
		costAmount: 'cost_amount',
		circularTrack: '#eee'

	 })
	 const  [maxParking, setMaxParking] = useState({
		 day: 3,
		 night: 6
	 })

	const date = new Date()
	const get_current_mins = date.getMinutes()
	const preHr_check = Math.floor(timerLogic.scrollTime(value) / 100)
	var minutes = Math.floor(timerLogic.scrollTime(value)%100)
	// var minutes = date.getMinutes()
	var hours = preHr_check  // fixes issue where

	const rem_minutes = 60 - get_current_mins

	const start_time_unit = ['am', 'pm'];
	const currentTime = new Date();
	var startTime = `${currentTime.getHours() > 12?currentTime.getHours() - 12: currentTime.getHours()}:${currentTime.getMinutes() < 10? `0${currentTime.getMinutes()}`: currentTime.getMinutes()}${currentTime.getHours() >= 12?start_time_unit[1]:start_time_unit[0]}`


	const min = 0;
	const max = 0.98;
	// get value within limits
	const valueWithinLimits = (v) => Math.round(v * 24) / 24

	//
	// custom range
	const range = [0, 24];
	// scaled range value
	const rangeValue = value * (range[1] - range[0]) + range[0];


	// FULL TIME CALCULATIONS.
	var expTime, expTimeInSecs

	expTime = time_obj.genrate_expired_time(Math.round(rangeValue), "")

	// console.log(hours)
	expTimeInSecs = time_obj.set_expTime_to_secs()
		// + (minutes * 60)
	setTimeServer(hours)
	// serviceCall(priceServer)
	// console.log(`hours: ${hours} minutes ${minutes}`)
	// console.log(`SEC: ${expTimeInSecs}`)
	// if (id == "extend"){
	// 	var expTime = localStorage.getItem('expTime')
	// }{
	// 	localStorage.setItem('expTime', expTime)
	//
	// }

	localStorage.setItem('expTimeInSecs', expTimeInSecs)
	localStorage.setItem('expTime', expTime)
	localStorage.setItem('total', totalbilling)



	// Keeps track of time changes

	 var startTime = time_obj.start_time()
	useEffect(() => {
		// if(darkMode >= 1800 || darkMode <= 600){
		// 		setDarkModeStyle({
		// 			circleContainer: 'timeInfo_dark',
		// 			costAmount: 'cost_amount_dark',
		// 			circularTrack: '#1B242F'
		// 		})
		// 	}

		setCameraInfo(JSON.parse(localStorage.getItem("camera")))
		setPlateNo(localStorage.getItem("license"))


		if (dayTime == 0) {
			setDayTimeValidation(true)
		} else { setDayTimeValidation(false) }
		if (nightTime == 0) {
			setNightTimeValidation(true)
		}
		localStorage.setItem('startTime', startTime)
		if(localStorage.getItem('realTime') == 1440 && props.addTime == '-0.041'){

			setValue(value + props.addTime );
		}
		// if(localStorage.getItem('realTime') == 0 && props.addTime == '-0.0417' && hours != 0){
		// 	setValue(value + props.addTime );
		// }


		if(hours >= 24){
			return;
		}
		if(hours <= 0 && props.addTime == '-0.041'){
			return;
		}
		else{
			setValue(value + props.addTime );
		}





	}, []);



	useEffect(() => {
		// console.log(Math.round(value))
		var duration = Math.round(rangeValue)*60;
		setLot(localStorage.getItem("lot"))

		try {
			const id = location.search.slice(1).split("&")[1].split("=")[1];
			if (id == "extend"){
				const guid = location.search.slice(1).split("&")[2].split("=")[1];
				localStorage.setItem("guid",guid);
				fetch(`${process.env.REACT_APP_URL_ONESHOT}/v1/Park/Extend?requestId=${guid}&duration=${duration}`, {
					method: 'GET',
					headers: {"Content-Type": "application/json"},

				}).then((res) => res.json())
					.then((data) => {
						setAllTotal(data.data.price)
						setReserveAmount(data.data.reserveAmount)
						localStorage.setItem('totalServer', data.data.price)


						// console.log("priceServer:"+data.data.price)

					})
					.catch(function (error) {
						console.log("no data")

					});




			}else{
				var startTime = Date.now();

				fetch(`${process.env.REACT_APP_URL_ONESHOT}/v2/Park/PreReserve?lotId=${lot}&startDate=${startTime}&duration=${duration}&plateno=${plateNo}`, {
					method: 'GET',
					headers: {"Content-Type": "application/json"},

				}).then((res) => res.json())
					.then((data) => {
						setAllTotal(data.data.price)

						localStorage.setItem('totalServer', data.data.price)
						setReserveAmount(data.data.reserveAmount)

						// console.log("priceServer:"+data.data.price)

					})
					.catch(function (error) {
						console.log("no data")

					});

			}
		}catch (e){

			var startTime = Date.now();

			fetch(`${process.env.REACT_APP_URL_ONESHOT}/v2/Park/PreReserve?lotId=${lot}&startDate=${startTime}&duration=${duration}&plateno=${plateNo}`, {
				method: 'GET',
				headers: {"Content-Type": "application/json"},

			}).then((res) => res.json())
				.then((data) => {
					setAllTotal(data.data.price)
					setReserveAmount(data.data.reserveAmount)
					localStorage.setItem('totalServer', data.data.price)
					console.log("priceServer:"+data.data.price)

				})
				.catch(function (error) {
					console.log("no data")

				});


		}

	}, [timeServer,priceServer]);
	setStartTime(startTime)
	setExpiredTime(expTime)

var test = 0
const scroll_wheel_hr = parseInt(localStorage.getItem('_time_btn_hr'))
const scroll_wheel_meridian = localStorage.getItem('_time_btn_meridian')



	return (
		<div className='timer_container'>
			<div className='timer_holder'>
				<div id={darkModeStyle.circleContainer} >
					<div id='' >
					<p className='set_time' >Set Duration</p>
					<p id='time' style={{color:'#000000'}}>{`${Math.round(rangeValue)}h:0m`}</p>
					{/*<p id='time' style={{color:'#fff'}}>{`${hours}h:${minutes}m`}</p>*/}

						{/* <p id='time'>{(hours == 5?'Max Parking':`${hours}h:${minutes}m`)}</p> */}

					<p className='set_time' style={{marginTop: '10px'}}>Expires at</p>
					{/*<p className='rate_' style={{textAlign: 'center' ,color:'#fff'}}> {formatTime(`${Math.round(rangeValue) + date.getHours()}:${date.getMinutes()}`)}</p>*/}
					<p className='rate_' style={{textAlign: 'center' ,color:'#000000'}}>{expTime}</p>
					{/* <button className='set_time_button' style={{marginTop: '10px'}}>PARK LONGER</button>*/}
					</div>
				</div>

				<CircularInput radius={140}
							   value={valueWithinLimits(value)}
							   onChange={(v) => setValue(valueWithinLimits(v))}
				>
					<CircularTrack strokeWidth={10} stroke={darkModeStyle.circularTrack} />
					<CircularProgress className='bar' stroke='#c53232' strokeWidth={12} />
					<CircularThumb r={15} fill='#FFF' stroke='#FFF' />
				</CircularInput>
			</div>
			{cameraInfo?.penaltyAmount != null &&  localStorage.getItem("typePay") != "extend" && <> <div className='cost_holder' ><div id={darkModeStyle.costAmount} >start Time: </div><div className='price' style={{fontsize:"14px"}}>{`${cameraInfo?.startDate.split('T')[0]} ${cameraInfo?.startDate.split('T')[1].slice(0,5)}`}</div></div>
			<div className='cost_holder' style={{marginTop:'-59px'}}><div id={darkModeStyle.costAmount}>penalty Amount: </div><div className='price'>${`${cameraInfo?.penaltyAmount < 0 ? 0 : cameraInfo?.penaltyAmount}`}</div></div>
			<div className='cost_holder' style={{marginTop:'-59px'}}><div id={darkModeStyle.costAmount}>reserve Amount: </div><div className='price'>${`${reserveAmount}`}</div></div></>}
			<div className='cost_holder' ><div id={darkModeStyle.costAmount}>Total Cost: </div><div className='price'>${`${allTotal < 0 ? 0 : allTotal}`}</div></div>

			<input className="global_circle_btns title" type="button" onClick={()=>{if(Math.round(rangeValue) > 0){setValue( value - 0.0417)}else{} }} value="-1 Hr"/>
			<input className="global_circle_btns title" type="button"  onClick={()=> {if(Math.round(rangeValue) < 24){setValue( value + 0.0417)}else{} }}  value="+1 Hr"/>

			<DailyParking link='/monthparking/'/>

			{/*<div className='cost_holder'><div id={darkModeStyle.costAmount}>Total Cost: </div><div className='price'>${`${totalbilling}`}</div></div>*/}

		</div>
	);
};


function formatTime(timeString) {
	const [hourString, minute] = timeString.split(":");
	const hour = +hourString % 24;
	var time =(hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
	return time;
}


export default Timer;
